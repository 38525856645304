import revive_payload_client_4sVQNw7RlN from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/MotoritOnline.Web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/MotoritOnline.Web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/src/MotoritOnline.Web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/src/MotoritOnline.Web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/MotoritOnline.Web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/src/MotoritOnline.Web/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_AUP22rrBAc from "/src/MotoritOnline.Web/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _02_datadog_plugin_gBUvF6hiyc from "/src/MotoritOnline.Web/plugins/02.datadog-plugin.ts";
import _03_global_error_handler_plugin_ksx9ObdEl9 from "/src/MotoritOnline.Web/plugins/03.global-error-handler-plugin.ts";
import apollo_plugin_mBQOhuPBPd from "/src/MotoritOnline.Web/plugins/apollo-plugin.ts";
import apollo_repository_plugin_ByNZG0DDfB from "/src/MotoritOnline.Web/plugins/apollo-repository-plugin.ts";
import feature_flags_3X7A4FWWIa from "/src/MotoritOnline.Web/plugins/feature-flags.ts";
import mitt_S0QU5gJPTl from "/src/MotoritOnline.Web/plugins/mitt.ts";
import trackjs_plugin_IVMvE73k0n from "/src/MotoritOnline.Web/plugins/trackjs-plugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  plugin_AUP22rrBAc,
  _02_datadog_plugin_gBUvF6hiyc,
  _03_global_error_handler_plugin_ksx9ObdEl9,
  apollo_plugin_mBQOhuPBPd,
  apollo_repository_plugin_ByNZG0DDfB,
  feature_flags_3X7A4FWWIa,
  mitt_S0QU5gJPTl,
  trackjs_plugin_IVMvE73k0n
]
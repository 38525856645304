import { datadogRum } from '@datadog/browser-rum';

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig();
  if (config.public.datadogEnabled) {
    datadogRum.init({
      applicationId: config.public.datadogApplicationId,
      clientToken: config.public.datadogClientToken,
      site: config.public.datadogSite,
      service: config.public.datadogService,
      env: process.env.NODE_ENV,
      version: config.public.releaseVersion as string,
      sessionSampleRate: Number(config.public?.datadogSampleRate ?? 0),
      sessionReplaySampleRate: Number(config.public?.datadogReplaySampleRate ?? 0),
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: config.public.datadogMaskAll,
    });
    if (Number(config.public?.datadogReplaySampleRate ?? 0) > 0) {
      datadogRum.startSessionReplayRecording(); // Used for session replays
    }
  }
});

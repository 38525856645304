import mitt, { Emitter } from 'mitt';

export default defineNuxtPlugin(() => {
  const emitter = mitt();

  return {
    // Using provide here allows Nuxt3 to generate typings
    provide: {
      bus: {
        $on: emitter.on,
        $emit: emitter.emit,
        $off: emitter.off,
      },
    },
  };
});

interface Bus {
  $on: Emitter<Record<string, unknown>>['on'];
  $emit: Emitter<Record<string, unknown>>['emit'];
  $off: Emitter<Record<string, unknown>>['off'];
}

declare module '#app' {
  interface NuxtApp {
    $bus: Bus;
  }
}
